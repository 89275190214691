<template >
  <MainNav />
  <section id="right_section" ref="main" @scroll="listenScrollPosition">
    <main-header />
    <router-view :key="$route.fullPath"></router-view>
    <main-footer/>
  </section>
  <section class="game-modal-container" v-if="gameComponent && gameComponent !== ''">
    <component :is="gameComponent" @click.stop />
  </section>
  <loading></loading>
</template>

<script>
import MainHeader from './Header'
import MainFooter from './Footer'
import MainNav from './Navigation'
import { mapState } from 'vuex'
import store from '@/store'
import Loading from '@/components/common/Loading.vue'

import Casino from '@/components/game/pc/Casino.vue'
import Slot from '@/components/game/pc/Slot.vue'
import Sports from '@/components/game/pc/Sports.vue'
import Mini from '@/components/game/pc/MiniGame.vue'

export default {
  name: 'DefaultLayout',
  components: {
    Loading,
    MainHeader,
    MainFooter,
    MainNav,
    Casino,
    Slot,
    Sports,
    Mini
  },
  computed: {
    ...mapState([
      'banner',
      'gameComponent'
    ])
  },
  mounted() {
    this.emitter.on('isSlot', (data) => {
      if (data) {
        const groupCode = data.groupCode
        const codeName = data.codeName
        const code = data.code
        this.getSlotOpen(groupCode, codeName, code)
      } else {
        this.isSlotOpen = false
      }
    })
  },
  data() {
    return {
      isSlotOpen: false,
      groupCode: '',
      codeName: '',
      code: '',
      isLoading: false
    }
  },
  methods: {
    listenScrollPosition(e) {
      const position = e.target.scrollTop
      store.commit('setMainScrollPosition', position)
    },
    getSlotOpen(groupCode, codeName, code) {
      if (this.userData) {
        this.groupCode = groupCode
        this.codeName = codeName
        this.code = code
        this.isSlotOpen = true
      } else {
        this.onCheck('front.error.afterSignin')
      }
    }
  }
}
</script>
<style>
.game-modal-container {
  background: #7c7c7c6e;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  transition: opacity 0.5s ease-in;
}

.fade-leave-active {
  transition: opacity 0.2s ease-in;
}

.fade-leave-to {
  opacity: 0;
}

.slide-fade-enter {
  transform: translateX(10px);
  opacity: 0;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.2s ease;
}

.slide-fade-leave-to {
  transform: translateX(-10px);
  opacity: 0;
}

.slide-up-enter {
  transform: translateY(185px);
  opacity: 0;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.3s ease;
}

.slide-up-leave-to {
  transform: translateY(0px);
  opacity: 0;
}
</style>
