<template>
  <footer>
    <div class="foot_conts">
      <div class="foot_left_wrap">
        <div class="foot_logo_copyright">
          <img src="@/assets/img/core/logo.png" alt="" width="166"/>
          <p>
            COPYRIGHT 2024, 138BET. ALL RIGHTS RESERVED. GAMBLING CAN BE<br/>
            ADDICTIVE, PLEASE PLAY RESPONSIBLY.<br/>
            FOR MORE INFORMATION ON SUPPORT TOOLS,<br/>
            PLEASE VISIT OUR RESPONSIBLE
            GAMBLING PAGE
          </p>
        </div>

        <div class="foot_menu">
          <dl>
            <dt>{{ $t('front.boardCategory.introduction') }}</dt>
            <dd>
              <a @click="openGameWelcome('Casino')" >{{ $t('front.gameCategory.casino') }}</a>
              <a @click="openGameWelcome('Slot')" >{{ $t('front.gameCategory.slot') }}</a>
              <a @click="openGameWelcome('Mini')" >{{ $t('front.gameCategory.minigame') }}</a>
              <a></a>
            </dd>
          </dl>
          <dl>
            <dt>{{ $t('front.common.cscenter') }}</dt>
            <dd>
              <router-link :to="'/mypage'">{{ $t('front.common.mypage') }}</router-link>
              <router-link :to="'/qnaList'">{{ $t('front.common.qna') }}</router-link>
              <router-link :to="'/betlist'">{{ $t('front.common.betHistory') }}</router-link>
              <router-link :to="'/noticelist'">{{ $t('front.common.notice') }}</router-link>
              <router-link :to="'/messagelist'">{{ $t('front.common.notes') }}</router-link>
            </dd>
          </dl>
          <dl>
            <dt>{{ $t('front.common.mypage') }}</dt>
            <dd>
              <!--<a href="/bbs/roulette.html">룰렛</a>-->
              <router-link :to="'/cash/deposit'">{{ $t('front.common.MakeAdeposit') }}</router-link>
              <router-link :to="'/cash/withdraw'">{{ $t('front.common.makeAwithdraw') }}</router-link>
              <router-link :to="'/point'">{{ $t('front.common.point') }}</router-link>
              <a></a>
              <a></a>
            </dd>
          </dl>
        </div>
      </div>
      <div class="foot_help">
        <div class="foot_img">
          <img src="@/assets/img/winner_pc/main/provider-logo-6.png" alt="">
        </div>
        <!-- <ul>
          <li>
            <a href="/noticelist?bo_table=z10">
              <img src="@/assets/img/winner_pc/icon/ic_foot_help_1.png" alt="" />
              <span>고객센터</span>
            </a>
          </li>
          <li>
            <a href="javascript:warning_popup('고객센터로 문의주세요.')">
              <img src="@/assets/img/winner_pc/icon/ic_foot_help_2.png" alt="" />
              <span>다운로드</span>
            </a>
          </li>
          <li>
            <a href="javascript:warning_popup('고객센터로 문의주세요.')">
              <img src="@/assets/img/winner_pc/icon/ic_foot_help_3.png" alt="" />
              <span>파트너제휴</span>
            </a>
          </li>
        </ul> -->
      </div>
    </div>
    <!-- <swiper :space-between="40" :grab-cursor="true" :a11y="false" :free-mode="true" :speed="11000" :loop="true"
      :slides-per-view="'auto'" :autoplay="{
          delay: 0.5, disableOnInteraction: false
        }" :breakpoints="{ /* when window >=0px - webflow mobile landscape/portriat */
      spaceBetween: 30,
      480: { /* when window >=0px - webflow mobile landscape/portriat */
        spaceBetween: 30,
      },
      767: { /* when window >= 767px - webflow tablet */
        spaceBetween: 40,
      },
      992: { /* when window >= 988px - webflow desktop */
        spaceBetween: 40,
      }
    }">
      <swiper-slide><img src="@/assets/img/winner_pc/foot_banner_1.png" alt="" /></swiper-slide>
      <swiper-slide><img src="@/assets/img/winner_pc/foot_banner_2.png" alt="" /></swiper-slide>
      <swiper-slide><img src="@/assets/img/winner_pc/foot_banner_3.png" alt="" /></swiper-slide>
      <swiper-slide><img src="@/assets/img/winner_pc/foot_banner_4.png" alt="" /></swiper-slide>
      <swiper-slide><img src="@/assets/img/winner_pc/foot_banner_5.png" alt="" /></swiper-slide>
      <swiper-slide><img src="@/assets/img/winner_pc/foot_banner_6.png" alt="" /></swiper-slide>
      <swiper-slide><img src="@/assets/img/winner_pc/foot_banner_7.png" alt="" /></swiper-slide>
      <swiper-slide><img src="@/assets/img/winner_pc/foot_banner_8.png" alt="" /></swiper-slide>
      <swiper-slide><img src="@/assets/img/winner_pc/foot_banner_9.png" alt="" /></swiper-slide>
      <swiper-slide><img src="@/assets/img/winner_pc/foot_banner_10.png" alt="" /></swiper-slide>
      <swiper-slide><img src="@/assets/img/winner_pc/foot_banner_11.png" alt="" /></swiper-slide>
      <swiper-slide><img src="@/assets/img/winner_pc/foot_banner_12.png" alt="" /></swiper-slide>
      <swiper-slide><img src="@/assets/img/winner_pc/foot_banner_13.png" alt="" /></swiper-slide>
      <swiper-slide><img src="@/assets/img/winner_pc/foot_banner_14.png" alt="" /></swiper-slide>
      <swiper-slide><img src="@/assets/img/winner_pc/foot_banner_15.png" alt="" /></swiper-slide>
    </swiper> -->
  </footer>
</template>

<script>
import { mapState } from 'vuex'
import store from '@/store'
// import { Swiper, SwiperSlide } from 'swiper/vue'
export default {
  name: 'Footer',
  components: {
    // Swiper,
    // SwiperSlide
  },
  computed: {
    ...mapState([
      'siteOption'
    ])
  },
  methods: {
    async openGameWelcome(value) {
      this.emitter.emit('Loading', true)
      await store.dispatch('storeGameComponent', value)
      this.emitter.emit('Loading', false)
    }
  }
}
</script>
<style scoped>
.swiper-container {
  width: 100%;
}

.swiper-wrapper {
  width: 100%;
}

.swiper-slide {
  width: fit-content !important;
}

/*Add code for smooth scrolling*/
.trusted-by-list {
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}

.foot_help img {
  /* width:90%; */
  height: 175px;
}

.foot_left_wrap {
  display: flex;
  flex-wrap: nowrap;
  gap: 10%;
}

footer .foot_conts .foot_menu {
  align-items: center;
}
footer .foot_conts .foot_menu dd a {
  min-height: 16px;
}
</style>
