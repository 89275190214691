<template>
  <div class="gamemodal">
    <button class="gamemodal-close-btn" type="button" @click="closeGameWelcome">
      <i class="fas fa-times"></i>
    </button>
    <div class="top_banner">
      <img src="@/assets/img/core/Minigame_list_header.png" />
    </div>
    <div class="main_minigame">
      <div class="right_cont">
        <div class="tit">MINI GAME</div>
        <div class="gameplay_list_wrap">
          <ul class="gameplay_list">
            <template v-if="vendorList && vendorList.length !== 0">
              <template v-for="item in vendorList" :key="item.procId">
                <li @click="goPage(item.procId)">
                  <div class="gameplay">
                    <div class="img"><img :src="loadBackground(item.procId)" alt="" /></div>
                    <!-- <div class="over"><img src="@/assets/img/winner_pc/play_over.png" alt="" /></div> -->
                  </div>
                </li>
              </template>
              <template v-if="vendorList.length % 4 !== 0">
                <li class="empty-flex" v-for="loop in 4-(vendorList.length % 4)" :key="loop"></li>
              </template>
            </template>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="@/styles/winner_pc/game.css"></style>
<script>
import { isMobile } from '@/libs/utils'
import { minigameDisplaySize } from '@/libs/constants'
import { getMiniGameUrl } from '@/api/game'
import store from '@/store'
import { mapState } from 'vuex'
export default {
  name: 'mini',
  components: {
  },
  data() {
    return {
      displayInfo: minigameDisplaySize,
      gameList: []
    }
  },
  computed: {
    ...mapState([
      'productList'
    ]),
    vendorList() {
      console.log(this.productList.filter(item => item.groupCode === 'minigame'))
      return this.productList.filter(item => item.groupCode === 'minigame')
    }
  },
  methods: {
    goPage(procId) {
      this.$router.push({ name: 'minigameViewer', params: { gameCode: procId } })
      this.closeGameWelcome()
    },
    closeGameWelcome(e) {
      store.dispatch('storeGameComponent', '')
    },
    // async _getMiniGameList() {
    //   try {
    //     const response = await getMiniGameList()
    //     const { resultMessage, data } = response.data

    //     if (resultMessage === 'SUCCESS') {
    //       const gameList = data.gameList
    //       if (gameList.length !== 0) {
    //         const imgFailList = []
    //         for (const item of gameList) {
    //           try {
    //             item.gameImg = require(`../../../assets/img/core/Main_minigame_list_${item.procId}.jpg`)
    //           } catch (e) {
    //             imgFailList.push(item.gameName)
    //           }
    //         }
    //         console.log(imgFailList)
    //       }
    //       this.gameList = data.gameList
    //     }
    //   } catch (e) {
    //     console.error(e)
    //   }
    //   console.log(this.gameList)
    // },
    async getGameUrl(game) {
      const device = ((device) => {
        let _device = '0'
        if (device) {
          _device = '1'
        }
        return _device
      })(isMobile())
      const reqModel = {
        siteName: this.siteName,
        id: this.userId,
        isMobile: device,
        lang: this.$i18n.locale
      }
      const windowSize = {
        coin5: 'width=1170px,height=920px',
        coin3: 'width=1170px,height=920px',
        pbg: 'width=1140px,height=880px',
        default: 'width=1140px,height=855px'
      }
      try {
        const gameWindow = window.open('/preload', '_blank', windowSize[game] || windowSize.default)
        this.emitter.emit('Loading', true)
        const response = await getMiniGameUrl(game, reqModel)
        const { data } = response.data
        if (data.gameurl) {
          gameWindow.location.href = data.gameurl
          this.emitter.emit('Loading', false)
        }
      } catch (e) {
        console.error(e)
      }
      this.emitter.emit('Loading', false)
    },
    loadBackground(procId) {
      // console.log(procId)
      try {
        return require(`@/assets/img/core/Minigame_list_${procId}.jpg`)
      } catch (e) {
        // console.log(e)
        return ''
      }
    }
  },
  async created() {
    // await this._getMiniGameList()
  }
}
</script>
<style scoped lang="scss" src="@/styles/game.scss">
</style>
<style scoped>
.main_minigame .gameplay_list_wrap {
  padding-bottom: 60px;
}
</style>
