import { createStore } from 'vuex'
import { IS_PRODUCTION } from './libs/constants'
import { getUserInfo } from '@/api/member'
import { getPopupCookie, removeAuthData, getLangCookie, tokenCheck } from '@/libs/auth-helper'
import { commonBoardCode, commonCode, gameGroupCodes, reqMainInfo, reqProductList } from '@/api/common'
import { getGameMoney } from '@/api/cash'
import { getBanner, getPopup, getPopupLogin, getSiteCheckOption, getSiteOption } from '@/api/main'
import { PAGE_TYPE } from '@/libs/constants'
// import { getUserInfo } from '@/api/member'

export default createStore({
  state: {
    errorData: null,
    userData: null,
    commonCode: null,
    commonCodeByCode: null,
    commonCodeByOrder: null,
    gameCount: null,
    coinAmt: null,
    siteOption: null,
    siteCheck: null,
    categoryCode: null,
    categoryCodeByCode: null,
    banner: null,
    popup: [],
    popupLogin: [],
    gameGroupCodes: null,
    unReadMessageCount: 0,
    devMode: false,
    gameList: [],
    productList: [],
    navToggle: false,
    mainScrollPosition: 0,
    gameComponent: '',
    pageType: process.env.VUE_APP_PAGE_TYPE,
    pageReloadKey: 0,
    mainInfo: {
      cashListIn: [],
      cashListOut: [],
      eventList: [],
      boardList: []
    }
  },
  getters: {
    gameGroupCodes(state) {
      return state.gameGroupCodes
    },
    pageReloadKey(state) {
      return state.pageReloadKey
    },
    pageType(state) {
      return state.pageType
    },
    gameComponent(state) {
      return state.gameComponent
    },
    mainScrollPosition(state) {
      return state.mainScrollPosition
    },
    navToggle(state) {
      return state.navToggle
    },
    gameList(state) {
      return state.gameList
    },
    devMode(state) {
      return state.devMode
    },
    banner(state) {
      return state.banner
    },
    popup(state) {
      return state.popup
    },
    popupLogin(state) {
      return state.popupLogin
    },
    userData() {
      return this.state.userData
    },
    commonCode(state) {
      return state.commonCode
    },
    userCoinAmt(state) {
      return state.coinAmt
    },
    mainInfo(state) {
      return state.mainInfo
    },
    siteOption(state) {
      return state.siteOption
    },
    commonCodeByCode(state) {
      return state.commonCodeByCode
    },
    commonCodeByOrder(state) {
      return state.commonCodeByOrder
    },
    categoryCode(state) {
      return state.categoryCode
    },
    categoryCodeByCode(state) {
      return state.categoryCodeByCode
    },
    productList(state) {
      return state.productList
    }
  },
  mutations: {
    setGameComponent(state, value) {
      state.gameComponent = value
    },
    setPageReloadKey(state, value) {
      const kewoad = `${value}_${new Date().getTime()}`
      state.pageReloadKey = kewoad
    },
    setMainScrollPosition(state, value) {
      state.mainScrollPosition = value
    },
    setNavToggle(state, value) {
      state.navToggle = value
    },
    setDevMode(state, value) {
      state.devMode = value
    },
    setSiteOption(state, value) {
      state.siteOption = value
    },
    setSiteCheck(state, value) {
      state.siteCheck = value
    },
    setUserData(state, value) {
      // console.log(value)
      state.userData = value
    },
    setErrorData(state, payload) {
      state.errorData = payload
    },
    setUserCoinAmt(state, payload) {
      state.coinAmt = payload
    },
    setMainInfo(state, payload) {
      state.mainInfo.eventList = payload.eventList
      state.mainInfo.boardList = payload.boardList
      state.mainInfo.cashListIn = payload.cashListIn
      state.mainInfo.cashListOut = payload.cashListOut
    },
    clearAllUserData(state) {
      this.state.popupLogin = []
      this.state.userData = null
    },
    setProductList(state, productList) {
      for (const product of productList) {
        // product.imgSrc = new URL(`./assets/img/product/${product.groupCode}/${product.procId}.png`, import.meta.url).href
        try {
          product.imgSrc = require(`./assets/img/logo/${product.procId}.png`)
        } catch (e) {
          product.imgSrc = ''
        }
        // product.imgSrc = `@/assets/img/product/${product.groupCode}/${product.procId}.png`
      }
      state.productList = productList
    },
    setCategoryCode(state, value) {
      // console.log('setCategoryCode', value)
      const gameObj = {}
      const obj = {}
      for (let i = 0, iLen = value.length; i < iLen; i++) {
        const item = value[i]
        const code = item.code.trim()
        const groupCode = item.groupCode.trim()

        if (!gameObj[groupCode]) {
          gameObj[groupCode] = []
        }

        if (!obj[groupCode]) {
          obj[groupCode] = {}
        }
        if (!obj[groupCode][code]) {
          obj[groupCode][code] = item
        }

        gameObj[groupCode].push(item)
      }
      // console.log(obj, gameObj)
      state.categoryCodeByCode = obj
      state.categoryCode = gameObj
    },
    setCommonCode(state, value) {
      const gameObj = {}
      const gameObjByCode = {}
      const gameCount = {}

      for (let i = 0, iLen = value.length; i < iLen; i++) {
        const item = value[i]
        const code = item.code.trim()
        const codeName = item.codeName.trim()
        const groupCode = item.groupCode.trim()
        const groupCodeNameEN = item.groupCodeNameEN.toString().toLowerCase().replace(/\s/g, '')

        if (!gameCount[groupCodeNameEN]) {
          gameCount[groupCodeNameEN] = 0
        }
        gameCount[groupCodeNameEN]++

        if (!gameObj[groupCode]) {
          gameObj[groupCode] = {}
        }
        if (!gameObj[groupCode][codeName]) {
          gameObj[groupCode][codeName] = item
        }

        if (!gameObjByCode[code]) {
          gameObjByCode[code] = {}
        }
        gameObjByCode[code] = {
          ...item
        }
      }

      const list = value
      list.sort((a, b) => {
        return a.ord > b.ord ? 1 : -1
      })

      const obj = {}

      list.forEach(item => {
        const groupCode = item.groupCode.trim()
        if (!obj[groupCode]) {
          obj[groupCode] = []
        }
        obj[groupCode].push({
          ...item
        })
      })
      this.state.gameCount = gameCount
      this.state.commonCodeByOrder = obj
      this.state.commonCodeByCode = gameObjByCode
      this.state.commonCode = gameObj
    },
    setGameGroupCodes(state, value) {
      state.gameGroupCodes = value
    },
    setUserCash(state, payload) {
      state.userData.cashAmt = payload
    },
    setUserPoint(state, payload) {
      state.userData.pointAmt = payload
    },
    setUserUnReadMessageCount(state, payload) {
      state.unReadMessageCount = payload
    },
    setBanner(state, payload) {
      state.banner = payload
    },
    setPopup(state, payload) {
      state.popup = payload
    },
    setPopupLogin(state, payload) {
      state.popupLogin = payload
    }
  },
  actions: {
    async storeMainInfo({ dispatch, commit }, payload) {
      const request = {
        userPageType: PAGE_TYPE
      }
      try {
        const response = await reqMainInfo(request);
        const { data } = response.data
        commit('setMainInfo', data)
      } catch (e) {
        console.log(e)
      }
    },
    storeProductList({ dispatch, commit }) {
      return reqProductList().then(response => {
        const result = response.data
        if (result.resultCode === '200') {
          const { productList } = result.data
          commit('setProductList', productList)
        }
      })
    },
    async storeGameComponent({ dispatch, commit }, value) {
      if(value) {
        const result = await tokenCheck(true)
        if (!result) {
          const lang = getLangCookie()
          let msg = '로그인 이후 이용 가능합니다.'
          if (lang !== 'ko') {
            msg = 'Please Log-in'
          }
          alert(msg)
          return
        }
      }
      commit('setGameComponent', value)
    },
    storeRunMode({ dispatch, commit }, value) {
      commit('setDevMode', value)
    },
    storeBanner({ dispatch, commit }, payload) {
      return getBanner({ userPageType: PAGE_TYPE }).then(response => {
        const result = response.data
        if (result.resultCode === '200') {
          const data = result.data
          commit('setBanner', data.list)
        }
      })
    },
    storePopup({ dispatch, commit }, payload) {
      if (this.state.popup.length === 0) {
        return getPopup({ userPageType: PAGE_TYPE }).then(response => {
          const result = response.data
          if (result.resultCode === '200') {
            const data = result.data
            const list = data.list

            list.forEach(item => {
              const siteId = process.env.VUE_APP_SITE_ID || 'moka'
              const name = siteId + '_popup_' + item.bannerIdx
              const cookie = getPopupCookie(name)
              if (cookie) {
                item.isClose = true
              } else {
                item.isClose = false
              }
            })
            commit('setPopup', list)
          }
        })
      }
    },
    storePopupLogin({ dispatch, commit }, payload) {
      if (this.state.popupLogin.length === 0) {
        return getPopupLogin({ userPageType: PAGE_TYPE }).then(response => {
          const result = response.data
          if (result.resultCode === '200') {
            const data = result.data
            const list = data.list

            list.forEach(item => {
              const siteId = process.env.VUE_APP_SITE_ID || 'moka'
              const name = siteId + '_popup_' + item.bannerIdx
              const cookie = getPopupCookie(name)
              if (cookie) {
                item.isClose = true
              } else {
                item.isClose = false
              }
            })
            commit('setPopupLogin', list)
          }
        })
      }
    },
    storeNavToggle({ dispatch, commit }, payload) {
      commit('setNavToggle', payload)
    },
    storeUserCash({ dispatch, commit }, payload) {
      commit('setUserCash', payload)
    },
    storeUserPoint({ dispatch, commit }, payload) {
      commit('setUserPoint', payload)
    },
    storeUserUnReadMessageCount({ dispatch, commit }, payload) {
      commit('setUserUnReadMessageCount', payload)
    },
    storeSiteOption({ dispatch, commit }, payload) {
      return getSiteOption({}).then(res => {
        const result = res.data
        if (result.resultCode === '200') {
          const data = result.data
          commit('setSiteOption', data.siteOption)
        }
      })
    },
    storeSiteCheck({ dispatch, commit }, payload) {
      const param = {
        siteId: process.env.VUE_APP_SITE_ID || 'moka'
      }
      return getSiteCheckOption(param).then(res => {
        const result = res.data
        if (result.resultCode === '200') {
          const data = result.data
          commit('setSiteCheck', data.siteCheckInfo)
        }
      })
    },
    storeUserCoinAmt({ dispatch, commit }, payload) {
      if (!this.state.userData) {
        return false
      } else {
        return getGameMoney({}).then(res => {
          const result = res.data
          if (result.resultCode === '200') {
            const data = result.data
            commit('setUserCoinAmt', data.balance)
          } else if (result.resultCode === 'TK999') {
            commit('setUserCoinAmt', result.resultCode)
          }else {
            commit('setUserCoinAmt', result.resultMessage)
          }
        })
      }
    },
    storeUserData({ dispatch, commit }, payload) {
      return getUserInfo(payload).then(response => {
        const data = response.data
        if (data.resultCode === '200') {
          commit('setUserData', data.data.member)
          return data
        } else {
          removeAuthData()
          this.$router.push('/')
        }
      }).catch(err => {
        console.error('[ERR] storeUserData : ', err)
      })
    },
    storeGameGroupCodes({ dispatch, commit }, payload) {
      payload = {
        masterCode: 'procId'
      }
      return gameGroupCodes(payload).then(response => {
        const data = response.data

        commit('setGameGroupCodes', data.data.groupCodes)
        return data
      })
    },
    storeCommonCode({ dispatch, commit }, payload) {
      payload = {
        masterCode: 'procId',
        groupCode: '',
        code: ''
      }
      return commonCode(payload).then(response => {
        const data = response.data
        // if (data.resultCode === '200') {
        commit('setCommonCode', data.data.codes)
        // return data
        // }
      }).catch(err => {
        console.error('[ERR] storeCommonCode : ', err)
      })
    },
    storeCategoryCode({ dispatch, commit }, payload) {
      payload = {
        masterCode: 'bcategory'
      }
      return commonBoardCode(payload).then(response => {
        const data = response.data
        // if (data.resultCode === '200') {
        commit('setCategoryCode', data.data.codes)
        // return data
        // }
      }).catch(err => {
        console.error('[ERR] setCategoryCode : ', err)
      })
    }
  },
  strict: IS_PRODUCTION
})
