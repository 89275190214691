<template>
  <div class="popup_mask" @click="loginClosePopupMask">
    <div id="login_popup" class="popup_area" style="background: none;">
      <div class="popup_inner">
        <div class="popup_box">
          <div class="popup_logo">
            <img src='@/assets/img/core/logo.png' />
          </div>
          <p>
            <i>
              <img src="@/assets/img/winner_pc/main/icon_password.png" alt="" />
            </i>
            <input type="password" id="mb_password" name="mb_password" :placeholder="$t('front.common.password')" v-model="model.partnerPass" />
          </p>
          <p v-if="isNew">
            <i>
              <img src="@/assets/img/winner_pc/main/icon_password.png" alt="" />
            </i>
            <input type="password" id="mb_password_chk" name="mb_password" :placeholder="$t('front.common.passwordCheck')" v-model="model.partnerPassUpdate" />
          </p>
          <div class="popup_input">
            <div class="d-f_1">
              <button type="button" @click="registPartnerPw">{{ $t('front.button.confirm') }}</button>
              <button type="button"  @click="closeModal">{{ $t('front.button.cancel') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getLoginId, setPartnerAuthCookie } from '@/libs/auth-helper'
import { registPartnerPassword, updatePartnerPassword } from '@/api/partner';

// import VueClientRecaptcha from 'vue-client-recaptcha'

export default {
  name: 'Signin',
  components: {
    // VueClientRecaptcha
  },
  props: ['isNew'],
  computed: {
    canSubmit() {
      return this.model.memId && this.model.memPass
    },
    loginId() {
      return getLoginId();
    }
  },
  watch: {
    loginId: {
      handler(loginId) {
        if (loginId) {
          this.saveId = true
          this.model.memId = loginId
        } else this.saveId = false
      },
      immediate: true
    }
  },
  data() {
    return {
      model: {
        partnerPass: '',
        partnerPassUpdate: ''
      },
      data: {
        captchaCode: null,
        isValid: false
      },
      saveId: false,
      inputValue: null
    }
  },
  methods: {
    async registPartnerPw() {
      try {
        const passFamily = this.model
        let result = null
        if (!this.isNew) {
          delete passFamily.partnerPassUpdate
          console.log('passcheck')
          result = await registPartnerPassword(passFamily)
        }else {
          console.log('passupdate')
          result = await updatePartnerPassword(passFamily)
        }

        const { resultCode, resultMessage, data } = result
        if (resultCode === '200') {
          console.log(Object.keys(passFamily).includes('passFamilyUpdate'))
          console.log(Object.keys(passFamily).length === 1)
          if (!this.isNew) {
            if (data.result === 'Y') {
              setPartnerAuthCookie(this.userData.memId)
              this.$router.push('/partner')
            } else {
              alert(this.$t('partner.gateConfirm.pwCheckFail'))
            }
          } else {
            if (data.result === 'Y') {
              alert(this.$t('partner.gateConfirm.pwUpdateSuccess'))
              this.closeModal();
            } else {
              alert(this.$t('partner.gateConfirm.pwUpdateFail'))
            }
          }
        } else {
          alert(this.$t('partner.gateConfirm.pwCheckFail'))
          console.log(resultMessage)
        }
      } catch (e) {
        alert(this.$t('partner.gateConfirm.pwCheckFail'))
        console.log(e)
        this.closeModal();
      }
    },
    confirm() {
      this.$emit('onConfirm', this.model)
    },
    closeModal() {
      this.$emit('closeModal', false)
    },
    loginClosePopupMask(e) {
      const target = e.target
      const filter = target.classList.contains('popup_mask')
      if (filter) {
        this.closeLogin()
      }
    },
    closeLogin() {
      this.$emit('close', 'signin')
    }
  }
}
</script>
<style scoped>
.login_popup_close-btn {
  position: absolute;
  font-size: 2rem;
  top:0;
  right:0;
  padding:20px 30px;
  z-index:10;
}
.popup_box {
  max-width: 320px;
  width:100%;
  background: linear-gradient(var(--primary),var(--primary-dark));
}
.popup_box .popup_logo {
  gap:20px;
}
.popup_area .popup_inner .popup_box .popup_input {
  height: 20%;
  /* background: #ffffff; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.popup_area .popup_inner input[type="password"] {
  padding-left: 0;
  margin-left: 20px;
}
.popup_area .popup_inner button {
  background: var(--primary);
}
</style>
