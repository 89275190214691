<template>
  <transition name="fade">
    <signin v-if="isLoginOpen" @close="onCloseSignin" @signup="isLoginOpen = false; isSignupOpen = true" />
  </transition>
  <transition name="fade">
    <new-message v-if="isUnReadMessage"></new-message>
  </transition>
  <div id="page_header">
    <div class="notice_area">
      <img src="@/assets/img/core/volume.png" />
      <div class="marquee-container">
        <Vue3Marquee v-if="marqueeList.length !== 0" :duration="10">
          <span v-for="(word, index) in marqueeList" :key="index" class="marquee-item">
            {{ word }}
          </span>
        </Vue3Marquee>
      </div>
    </div>
    <div class="spacer"></div>
    <template v-if="!userData">
      <div class="log_area">
        <!-- <ui-button :className="'loginbtn'" :text="$t('front.common.signup')" @click="onClickSignup"/> -->
        <button class="myButton" @click="onClickLogin">{{ $t('front.gnb.login') }}</button>
        <RouterLink to="/signup" class="myButton">{{ $t('front.common.signup') }}</RouterLink>
      </div>
    </template>
    <template v-else>
      <div class="log_area">
        <span>
          <img src="@/assets/img/core/won_2.png" alt="" />
          {{ thousand(userData.cashAmt ? userData.cashAmt.toString() : '0') }}
        </span>
        <span>
          <img src="@/assets/img/core/point_2.png" alt="" />
          {{ thousand(userData.pointAmt ? userData.pointAmt.toString() : '0') }} P
        </span>
        <span class="ic_refresh"><a href="#"><img src="@/assets/img/core/reload.png" alt="" /></a></span>
        <span><img :src="userLevelImage" />{{ userData.memNick }}</span>
        <!-- <router-link class="myButton">{{ $t('front.header.pointConversion') }}</router-link> -->
        <button class="myButton" @click="openPointExchange()">{{ $t('front.header.pointConversion') }}</button>
        <RouterLink class="myButton" to="/mypage">{{ $t('front.header.ChangingInfo') }}</RouterLink>
        <button class="myButton" @click="signOut">{{ $t('front.common.logout') }}</button>
      </div>
    </template>
    <div class="logindropdown" @mouseover="gnbMenuOpen('lang')" @mouseleave="showMenuLang = false">
      <button class="logindropbtn langbtn">
        <span class="langsp lang" v-if="$i18n.locale === 'ko'"><img src="@/assets/img/kr.svg"/><span>KR</span></span>
        <span class="langsp lang" v-if="$i18n.locale === 'en'"><img src="@/assets/img/us.svg"/><span>US</span></span>
        <span class="langsp lang" v-if="$i18n.locale === 'th'"><img src="@/assets/img/th.svg"/><span>TH</span></span>
        <span class="langsp lang" v-if="$i18n.locale === 'cn'"><img src="@/assets/img/cn.svg"/><span>CN</span></span>
        <span class="langsp lang" v-if="$i18n.locale === 'jp'"><img src="@/assets/img/jp.svg"/><span>JP</span></span>
        <span class="langsp lang" v-if="$i18n.locale === 'vn'"><img src="@/assets/img/vn.svg"/><span>VN</span></span>
      </button>
      <transition name="fade">
        <div class="logindropdown-content lang">
          <a href="#" :class="{ 'on': $i18n.locale === 'ko' }" @click="changeLocale('ko')">
            <img src="@/assets/img/kr.svg"/>
            <span>KR</span>
          </a>
          <a href="#" :class="{ 'on': $i18n.locale === 'en' }" @click="changeLocale('en')">
            <img src="@/assets/img/us.svg"/>
            <span>US</span>
          </a>
          <a href="#" :class="{ 'on': $i18n.locale === 'th' }" @click="changeLocale('th')">
            <img src="@/assets/img/th.svg"/>
            <span>TH</span>
          </a>
          <a href="#" :class="{ 'on': $i18n.locale === 'cn' }" @click="changeLocale('cn')">
            <img src="@/assets/img/cn.svg"/>
            <span>CN</span>
          </a>
          <a href="#" :class="{ 'on': $i18n.locale === 'jp' }" @click="changeLocale('jp')">
            <img src="@/assets/img/jp.svg"/>
            <span>JP</span>
          </a>
          <a href="#" :class="{ 'on': $i18n.locale === 'vn' }" @click="changeLocale('vn')">
            <img src="@/assets/img/vn.svg"/>
            <span>VN</span>
          </a>
        </div>
      </transition>
    </div>
  </div>

  <div class="popup_mask" v-if="isPointExchageVisible">
    <!-- 포인트 전환 팝업 -->
    <div class="popup1">
      <div class="popup_inner">
        <div class="title">
          <span @click="closePointExchange()"></span>
        </div>
        <div class="signup_logo">
          <img src='@/assets/img/core/logo.png' />
        </div>
        <dl>
          <dt>{{ $t('front.cash.nav4') }}</dt>
          <dd>{{ thousand(currentPoint.toString()) }} P</dd>
        </dl>
        <dl>
          <dt>{{ $t('front.point.changePoint') }}</dt>
          <dd>
            <input class="money_input" type="text" pattern="[0-9.,]+" :placeholder="$t('front.cash.inputNumber')" v-model="changePoint" />
          </dd>
        </dl>
        <div class="d-f">
          <button type="button" @click="setPoint('all')">{{ $t('front.cash.all') }}</button>
          <button type="button" @click="onSubmit">{{ $t('front.point.pointTrans') }}</button>
        </div>
        <RouterLink to="point" class="tx">{{ $t('front.mypage.pointChangeList') }}</RouterLink>
      </div>
    </div>
  </div>
</template>

<script>
// import Join from '@/components/member/Join'
// import JoinComplete from '@/components/member/JoinComplete'
import NewMessage from '@/components/member/Message.vue'
// componets import
import Signin from '@/components/member/Signin'

import { mapState } from 'vuex'

import { TRIPLE_GAME_LIST, PAGE_TYPE } from '@/libs/constants'
import { thousand } from '@/libs/utils'
import { setChangePoint } from '@/api/point'
import { getBoardList } from '@/api/board'
export default {
  name: 'Header',
  data() {
    return {
      isPopupOpen: false,
      currentPoint: 0,
      changePoint: '',
      remainPoint: 0,
      menuList: [],
      isLoginOpen: false,
      isSignupOpen: false,
      isSignupCompleteOpen: false,
      showMenu: false,
      showMenuMoney: false,
      showMenuLang: false,
      isAlertOpen: false,
      isGameOpen: false,
      isSlotOpen: false,
      isSportsOpen: false,
      isMiniOpen: false,
      isCashOpen: false,
      tripleGameList: TRIPLE_GAME_LIST,
      isLoggedIn: false,
      isLoginVisible: false,
      isSignUpVisible: false,
      isPopupMaskVisible: false,
      isPointExchageVisible: false,
      marqueeList: []
    }
  },
  components: {
    NewMessage,
    Signin
    // JoinComplete,
    // Join,
  },
  methods: {
    async getMarqueeList() {
      const request = {
        page: 1,
        count_per_list: 5,
        boardType: 'mq',
        startDate: '',
        endDate: '',
        searchValue: '',
        userPageType: PAGE_TYPE
      }
      try {
        const response = await getBoardList(request)
        const { resultCode, data, resultMessage } = response.data
        if (resultCode === '200') {
          const marqueeList = []
          data.list.forEach(item => {
            marqueeList.push(item.content)
          });
          this.marqueeList = marqueeList
        } else {
          throw new Error(`Request Failed: ${resultMessage}`)
        }
      } catch (e) {
        console.log(e)
      }
    },
    setPoint(value) {
      let point = Number(this.changePoint.replace(/,/g, ''))
      if (value === 'all') {
        point = this.currentPoint
      } else {
        if (value) {
          point += value
        } else {
          point = ''
        }
      }

      console.log(this.currentPoint)

      this.changePoint = thousand(point)
    },
    async onSubmit() {
      if (this.changePoint) {
        // console.log(this.limitMinPoint)
        // if (this.limitMinPoint > this.changePoint) {
        //   this.onCheck('front.point.limitPoint')
        //   return false
        // }

        const confirm = await this.onConfirm('front.point.confirm')
        if (confirm) {
          const param = {
            pointAmt: Number(this.changePoint.replace(/,/g, ''))
          }

          setChangePoint(param).then(async response => {
            const result = response.data
            if (result.resultCode === '200') {
              const success = await this.onCheck('front.point.success')
              if (success) {
                location.reload()
              }
            } else {
              let alertMessage = ''
              if(result.resultCode === 'C203') {
                alertMessage = this.$t(`api.${result.resultCode}`, { pointLimit: this.thousand(this.siteOption.pointChangeAmtLimit) })
              }else if(result.resultCode === 'C205') {
                alertMessage = this.$t(`api.${result.resultCode}`, { pointUnit: this.thousand(this.siteOption.pointChangeAmtUnit) })
              } else {
                alertMessage = this.$t(`api.${result.resultCode}`)
              }
              await this.onCheck(alertMessage)
            }
          })
        }
      } else {
        await this.onCheck('front.point.check')
      }
    },
    login() {
      this.isLoggedIn = true
      localStorage.setItem('isLoggedIn', true)
      this.isLoginVisible = false
    },
    logout() {
      this.isLoggedIn = false
      localStorage.removeItem('isLoggedIn')
    },
    openLogin() {
      this.isLoginVisible = true
    },
    closeLogin() {
      this.isLoginVisible = false
    },
    openPointExchange() {
      this.isPointExchageVisible = true
      this.currentPoint = this.userData.pointAmt || 0
    },
    closePointExchange() {
      this.isPointExchageVisible = false
    },
    slotOpen(event, groupCode, codeName, code) {
      this.emitter.emit(event, { groupCode, codeName, code })
    },
    gnbReset() {
      this.isGameOpen = false
      this.isSlotOpen = false
      this.isSportsOpen = false
      this.isMiniOpen = false
      this.isCashOpen = false
      this.showMenu = false
      this.showMenuLang = false
    },
    gnbMenuOpen(type) {
      this.gnbReset()
      if (type === 'game') {
        this.isGameOpen = true
      } else if (type === 'slot') {
        this.isSlotOpen = true
      } else if (type === 'cash') {
        this.isCashOpen = true
      } else if (type === 'mypage') {
        this.showMenu = true
      } else if (type === 'lang') {
        this.showMenuLang = true
      } else if (type === 'sport') {
        this.isSportsOpen = true
      } else if (type === 'minigame') {
        this.isMiniOpen = true
      }
    },
    getHeadMsgList() {
      // getHeadMsg({}).then(response => {
      //   console.log(response)
      // })
    },
    setMenu() {
      const list = this.$router.getRoutes()
      for (let i = 0, iLen = list.length; i < iLen; i++) {
        const item = list[i]
        if (item.meta && item.meta.gnb) {
          item.isSubOpen = false
          this.menuList.push(item)
        }
      }
    },
    onClickGame(code, name) {
      this.gnbReset()
      if (code) {
        // minigame

        if (code === 'mg') {
          this.$router.push({ name: name })
        } else {
          this.onSelectGame(code)
        }
      } else {
        this.onCheck(this.$t('front.header.inready'))
      }
    },
    onClickGnb(item) {
      const name = item.name
      const meta = item.meta
      if (meta.game) {
        this.onSelectGame(meta.gameCode)
      } else {
        this.$router.push({ name: name })
      }
    },
    onClickLogin() {
      this.isLoginOpen = true
      this.isSignupOpen = false
    },
    onClickSignup() {
      this.isLoginOpen = false
      this.isSignupOpen = true
    },
    onCloseSignin(value) {
      this.isLoginOpen = false
      if (value === 'join') {
        this.goPageByName('signup')
      }
      if (value === 'signin') {
        // location.reload()
      }
    },
    onCloseSignup(status) {
      this.isSignupOpen = false
      if (status) {
        this.isSignupCompleteOpen = true
      }
    }
  },
  computed: {
    ...mapState([
      'userData',
      'coinAmt',
      'commonCodeByOrder',
      'gameCount',
      'unReadMessageCount',
      'siteOption'
    ]),
    userLevelImage() {
      return require(`../assets/img/winner_pc/member/icon_level_${this.userData.memLevel}.png`)
    },
    isUnReadMessage() {
      return this.unReadMessageCount && this.unReadMessageCount && this.unReadMessageCount !== '0' && this.$route.name.indexOf('message') === -1 && this.$route.name.indexOf('msg') === -1
    }
  },
  watch: {
    $route: {
      handler() {
        this.isLoginVisible = false
        this.isPointExchageVisible = false

        // origin source
        // console.log('$route name', this.$route)
        // this.gnbReset()
      }
    },
    changePoint() {
      const tempcgPoint1 = Number(this.currentPoint)
      const tempcgPoint2 = Number(this.changePoint.replace(/,/g, ''))
      if (tempcgPoint1 < tempcgPoint2) {
        this.onCheck('front.point.enoughChangePoint')
        this.changePoint = ''
        return false
      } else {
        this.remainPoint = this.currentPoint - Number(this.changePoint.replace(/,/g, ''))

        const parts = this.changePoint.split('.')
        const v = parts[0].replace(/\D/g, '')
        const dec = parts[1]
        const calcNum = Number((dec !== undefined ? v + '.' + dec : v))
        // use this for numeric calculations
        console.log('number for calculations: ', calcNum)
        let n = new Intl.NumberFormat('en-EN').format(v)
        n = dec !== undefined ? n + '.' + dec : n
        this.changePoint = n
      }
    },
    userData: {
      handler(value) {
        if(value) {
          this.getMarqueeList();
        }
      },
      immediate: true
    }
  },
  created() {
    const isLoggedIn = localStorage.getItem('isLoggedIn')
    if (isLoggedIn) {
      this.isLoggedIn = true
    } else {
      this.isLoggedIn = false
    }
    this.setMenu()
    this.getHeadMsgList()
  }
}
</script>
<style src="@/styles/header.css" scoped />
<style scoped>
.langbtn {
  padding:0;
}

.marquee-container {
  width:460px;
  font-size:1rem;
  position: relative;
}
.vue3-marquee {
  overflow-y: hidden;
}
.marquee-item {
  font-weight: 900;
  color:#fff;
}
</style>
