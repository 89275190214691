<template>
  <div class="gamemodal">
    <button class="gamemodal-close-btn" type="button" @click="closeGameWelcome">
      <i class="fas fa-times"></i>
    </button>
    <div class="top_banner">
      <img src="@/assets/img/core/Sports_list_header.png" />
    </div>
    <div class="casino">
      <div class="right_cont">
        <div class="tit">SPORTS</div>
        <div class="game-list-wrap">
          <ul class="game_list">
            <template v-if="vendorList && vendorList.length !== 0">
              <template v-for="(item, index) in vendorList" :key="item.procId">
                <li>
                  <div class="gamebox" @click="goPage(`${item.procId}`, `${item.lobbySymbol}`)">
                    <div class="img">
                      <img :src="loadBackground(index+1)" alt="" />
                    </div>
                    <div class="logo">
                      <img :src="loadLogoImg(item.procId)" alt="" />
                    </div>
                    <div class="txt">{{ $t(`front.gameCode.sports.${item.procId}`) }}</div>
                  </div>
                </li>
              </template>
              <template v-if="vendorList.length % 4 !== 0">
                <li class="empty-flex" v-for="loop in 4-(vendorList.length % 4)" :key="loop"></li>
              </template>
            </template>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from '@/store'
import { isMobile } from '@/libs/utils'
import { getGameUrlSports } from '@/api/game'
import { mapState } from 'vuex'

export default {
  name: 'SportsViews',
  computed: {
    ...mapState([
      'productList'
    ]),
    vendorList() {
      return this.productList.filter(item => item.groupCode === 'sports')
    }
  },
  methods: {
    pageMove() {
      this.closeGameWelcome()
    },
    goPage(vendor, gameCode) {
      this.$router.push({ name: 'sportsViewer', params: { vendor: vendor, gameCode: gameCode } })
      this.closeGameWelcome()
    },
    async getGameUrl(item, gameKey) {
      this.emitter.emit('Loading', true)
      const params = {
        gameGroup: 'sports',
        vendorKey: item,
        isMobile: isMobile() ? '1' : '0',
        gameKey: gameKey
      }
      try {
        for (const key in params) {
          if (!params[key]) delete params[key]
        }
        const response = await getGameUrlSports(params)
        const { resultCode, resultMessage, data } = response.data
        if (resultCode === '200' && resultMessage === 'SUCCESS') {
          this.gameUrl = data.gameUrl
          const height = screen.height
          window.open(this.gameUrl, '_blank', `width=1380,height=${height}`)
        } else {
          throw new Error(resultCode)
        }
      } catch (e) {
        console.error(e)
        alert(this.$t('api.G9999'))
        // console.log(this.$router)
      }
      this.emitter.emit('Loading', false)
    },
    closeGameWelcome() {
      store.dispatch('storeGameComponent', '')
    },
    loadLogoImg(procId) {
      // console.log(procId)
      try {
        return require(`@/assets/img/logo/${procId}.png`)
      } catch (e) {
        console.log(e)
        return ''
      }
    },
    loadBackground(index) {
      // console.log(procId)
      try {
        return require(`@/assets/img/core/sports_list_${index}.jpg`)
      } catch (e) {
        // console.log(e)
        return ''
      }
    }
  }
}
</script>
<style scoped src="@/styles/winner_pc/sports.css" />
<style scoped lang="scss" src="@/styles/game.scss"></style>
<style scoped>
.casino .game_list {
  overflow: auto;
}

.casino .right_cont {
  height: auto;
}

.game-list-wrap {
  height: calc(100% - 210px);
  width: 93%;
  max-width: 1550px;
  margin: 0 auto;
  box-sizing: border-box;
  overflow-y: scroll;
  padding: 0px 49px 60px;
}
</style>
