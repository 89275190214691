<template>
  <div class="gamemodal mobile">
    <button class="gamemodal-close-btn" type="button" @click="closeGameWelcome">
      <i class="fas fa-times"></i>
    </button>
    <div class="top_banner">
      <img src="../../../assets/img/core/Sports_list_header.png"/>
    </div>
    <div class="casino modal-body">
      <div class="right_cont">
        <div class="tit">
          SPORTS
        </div>
        <div class="game-list-wrap">
          <ul class="game_list">
            <template v-if="vendorList && vendorList.length !== 0">
              <template v-for="(item,index) in vendorList" :key="item.procId">
                <li>
                  <div class="gamebox" @click="getGameUrl(`${item.procId}`, `${item.lobbySymbol}`)">
                    <div class="img"><img :src="loadBackground(index+1)" alt=""/></div>
                    <div class="logo"><img :src="loadLogoImg(item.procId)" alt=""/>
                    </div>
                    <div class="txt">{{ $t(`front.gameCode.sports.${ item.procId }`) }}</div>
                  </div>
                </li>
              </template>
            </template>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from '@/store'
import { isMobile } from '@/libs/utils';
import { getGameUrlSports } from '@/api/game';
import { mapState } from 'vuex'
export default {
  name: 'SportsViews',
  computed: {
    ...mapState([
      'productList'
    ]),
    vendorList() {
      return this.productList.filter(item => item.groupCode === 'sports')
    }
  },
  methods: {
    pageMove() {
      this.closeGameWelcome()
    },
    async getGameUrl(item, gameKey) {
      const gameWindow = window.open('/preload', '_blank', 'width=1280,height=720')
      this.emitter.emit('Loading', false)
      const params = {
        gameGroup: 'sports',
        vendorKey: item,
        isMobile: isMobile() ? '1' : '0',
        gameKey: gameKey,
        lang: this.$i18n.locale
      }
      try {
        for (const key in params) {
          if (!params[key]) delete params[key]
        }
        const response = await getGameUrlSports(params)
        const { resultCode, resultMessage, data } = response.data
        if (resultCode === '200' && resultMessage === 'SUCCESS') {
          gameWindow.location.href = data.gameUrl
          this.emitter.emit('Loading', false)
        } else {
          gameWindow.close()
          throw new Error(this.$t(`api.${resultCode}`))
        }
      } catch (e) {
        alert(e.message)
        console.log(e)
        // console.log(this.$router)
      }
      this.emitter.emit('Loading', false)
    },
    closeGameWelcome(e) {
      console.log(e)
      store.dispatch('storeGameComponent', '')
    },
    loadLogoImg(procId) {
      // console.log(procId)
      try {
        return require(`@/assets/img/logo/${procId}-1.png`)
      } catch (e) {
        console.log(e)
        return ''
      }
    },
    loadBackground(index) {
      // console.log(procId)
      try {
        console.log(index)
        return require(`@/assets/img/core/sports_list_${index}.jpg`)
      } catch (e) {
        console.log(e)
        return ''
      }
    }
  }
}
</script>
<style scoped lang="scss">
.casino .game_list {
  justify-content: center;
  overflow: auto;
  li {
    max-width: 50%;
  }
}

</style>
<style scoped src="@/styles/winner_pc/sports.css" />
<style lang="scss" scoped>
@import '@/styles/game.scss';
</style>
